import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Drawer, Typography, Grid } from "@material-ui/core"

// import app components
import {
  BackgroundImage,
  Button,
  Edges,
  PageIntro,
  Spacer,
  Textarea
} from "components"
import { useStore } from "store"
import { colors } from "theme"
import { getIcon } from "utils"

export default function Modelcomparison(props) {
  const {
    data: {
      page: {
        templateModelcomparison: {
          modelComparisonFields: {
            modelComparisonHero: { image, headline, text }
            // sections: {
            //   deluxe,
            //   luxurySport,
            //   lightweightDeluxe,
            //   lightweight,
            //   supertenderOpen,
            //   superlightOpen
            // }
          }
        }
      },
      boats: { nodes }
    }
  } = props

  const [
    {
      appState: { tenderComparison }
    }
  ] = useStore()
  const [modelsCompared, setModelsCompared] = useState(0)
  const [buttonsActive, setButtonsActive] = useState(true)
  const [modelComparison, setModelComparison] = useState([{}, {}, {}])
  const [compareOpen, setCompareOpen] = useState(false)

  const [deluxeGroup, setDeluxeGroup] = useState([])
  const [luxurySportGroup, setLuxurySportGroup] = useState([])
  const [lightweightdeluxeGroup, setLightweightdeluxeGroup] = useState([])
  const [lightweightGroup, setLightweightGroup] = useState([])
  const [supertenderopenGroup, setSupertenderopenGroup] = useState([])
  const [superlightopenGroup, setSuperlightopenGroup] = useState([])

  const [filteredListGroup, setFilteredListGroup] = useState([])

  const [lightweightDescription, setLightweightDescription] = useState([])
  const [lightweightDeluxeDescription, setLightweightDeluxeDescription] =
    useState([])
  const [deluxeDescription, setDeluxeDescription] = useState([])
  const [luxurySportDescription, setLuxurySportDescription] = useState([])
  const [superlightOpenDescription, setSuperlightOpenDescription] = useState([])
  const [supertenderOpenDescription, setSupertenderOpenDescription] = useState(
    []
  )
  const [walkerbayDescription, setWalkerbayDescription] = useState([])
  const [walkerbayGroup, setWalkerbayGroup] = useState([])

  const [drawerState, setDrawerState] = useState(false)
  const [resultsWeight, setResultsWeight] = useState([])
  const [resultsLength, setResultsLength] = useState([])
  const [resultsType, setResultsType] = useState("")
  const [filterActive, setFilterActive] = useState(false)

  const heightRef = useRef()

  function sortTenderComparison(filters) {
    let boatLength = [
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23"
    ]
    let boatWeight = 9999
    let boatType = ["console", "open"]
    function generateNumberArray(start, end) {
      const numberArray = []
      const startInt = parseInt(start)
      const endInt = parseInt(end)

      for (let i = startInt; i <= endInt; i++) {
        numberArray.push(i.toString())
      }

      return numberArray
    }
    let defaultValues = true

    if (!!filters.boatType && filters.boatType.length > 0) {
      boatType = filters.boatType
      defaultValues = false
    }

    if ((filters.maxLength, filters.minLength)) {
      boatLength = generateNumberArray(filters.minLength, filters.maxLength)
      defaultValues = false
    }
    // if (!!filters.boatLength && filters.boatLength.length > 0) {
    //   boatLength = filters.boatLength.split("-").sort((a, b) => a - b)
    //   defaultValues = false
    // }

    // {
    //   console.log("boatLength", boatLength)
    // }

    if (!!filters.boatWeight && filters.boatWeight.length > 0) {
      boatWeight = parseInt(filters.boatWeight)
      defaultValues = false
    }

    if (!defaultValues) {
      setFilterActive(true)
    }

    setResultsType(boatType)
    setResultsWeight(boatWeight)
    setResultsLength(boatLength)

    const filteredList = nodes.filter(
      (item) =>
        (item.acf.boatType === boatType ||
          boatType.includes(item.acf.boatType)) &&
        boatWeight >= parseInt(item.acf.boatMaximumWeight) &&
        boatLength.includes(item.acf.boatLength)
    )

    setFilteredListGroup(filteredList)

    // console.log(nodes)
  }

  useEffect(() => {
    sortTenderComparison(tenderComparison)

    nodes.map((o, i) => {
      console.log(o.boatfamilies?.nodes[0]?.name)
      if (o.boatfamilies?.nodes[0]?.name === "Deluxe") {
        deluxeGroup.push(o)
        setDeluxeDescription(o.boatfamilies?.nodes[0]?.description)
      } else if (o.boatfamilies?.nodes[0]?.name === "Explorer Sport") {
        luxurySportGroup.push(o)
        setLuxurySportDescription(o.boatfamilies?.nodes[0]?.description)
      } else if (o.boatfamilies?.nodes[0]?.name === "Lightweight Deluxe") {
        lightweightdeluxeGroup.push(o)
        setLightweightDeluxeDescription(o.boatfamilies?.nodes[0]?.description)
      } else if (o.boatfamilies?.nodes[0]?.name === "Lightweight") {
        lightweightGroup.push(o)
        setLightweightDescription(o.boatfamilies?.nodes[0]?.description)
      } else if (o.boatfamilies?.nodes[0]?.name === "Supertender Open") {
        supertenderopenGroup.push(o)
        setSupertenderOpenDescription(o.boatfamilies?.nodes[0]?.description)
      } else if (
        o.boatfamilies?.nodes[0]?.name === "Luxury Sport and Recreation"
      ) {
        walkerbayGroup.push(o)
        setWalkerbayDescription(o.boatfamilies?.nodes[0]?.description)
      } else if (o.boatfamilies?.nodes[0]?.name === "Superlight Open") {
        superlightopenGroup.push(o)
        setSuperlightOpenDescription(o.boatfamilies?.nodes[0]?.description)
      } else {
      }
    })

    function sortByLength(b, a) {
      if (parseInt(a.acf.boatLength) < parseInt(b.acf.boatLength)) {
        return -1
      }
      if (parseInt(a.acf.boatLength) > parseInt(b.acf.boatLength)) {
        return 1
      }
      return 0
    }

    deluxeGroup.sort(sortByLength)
    luxurySportGroup.sort(sortByLength)
    lightweightdeluxeGroup.sort(sortByLength)
    lightweightGroup.sort(sortByLength)
    supertenderopenGroup.sort(sortByLength)
    superlightopenGroup.sort(sortByLength)
    walkerbayGroup.sort(sortByLength)

    setDeluxeGroup(deluxeGroup)
    setLuxurySportGroup(luxurySportGroup)
    setLightweightdeluxeGroup(lightweightdeluxeGroup)
    setLightweightGroup(lightweightGroup)
    setSupertenderopenGroup(supertenderopenGroup)
    setSuperlightopenGroup(superlightopenGroup)
    setWalkerbayGroup(walkerbayGroup)
  }, [])

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerState(open)
  }

  const truncate = (theText, length) => {
    if (!theText) {
      return ""
    }

    if (!length) {
      length = 150
    }

    return theText.length > length
      ? theText.substring(0, length - 20) + "..."
      : theText
  }

  const handleModelCompare = (e, modelObj) => {
    setDrawerState(true)
    // Model is already being compared || we're already at 3 models
    if (modelComparison.includes(modelObj)) {
      // TODO, add dialog modal here with same message
      console.log("already chose this model")
      return
    }

    // Disable compare buttons
    if (modelsCompared >= 2) {
      setButtonsActive(false)
    }

    // Don't update anything
    if (modelsCompared === 3) {
      // TODO, add dialog modal here with same message
      console.log("hit max models")
      return
    }

    modelComparison[modelsCompared] = modelObj

    setModelsCompared(modelsCompared + 1)
  }

  const handleFullComparison = () => {
    if (!compareOpen) {
      heightRef.current.classList.add("comparison-open")
    } else {
      heightRef.current.classList.remove("comparison-open")
    }

    setDrawerState(false)
    setCompareOpen(!compareOpen)
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0)
    }
  }

  const handleClear = () => {
    setModelsCompared(0)
    setButtonsActive(true)
    setModelComparison([{}, {}, {}])
  }

  const handleClearModel = (model) => {
    const updatedList = modelComparison.map((o) => {
      if (o.title?.includes(model.title)) {
        return (o = {})
      } else {
        return o
      }
    })

    function emptyLast(ascending) {
      return function (a, b) {
        // equal items sort equally
        if (a === b) {
          return 0
        }
        // Put the empty objects last.
        else if (Object.keys(a).length === 0) {
          return 1
        } else if (Object.keys(b).length === 0) {
          return -1
        }
        // otherwise, if we're ascending, lowest sorts first
        else if (ascending) {
          return a < b ? -1 : 1
        }
        // if descending, highest sorts first
        else {
          return a < b ? 1 : -1
        }
      }
    }

    updatedList.sort(emptyLast(true))

    setButtonsActive(true)
    setModelsCompared(modelsCompared - 1)
    setModelComparison(updatedList)
  }

  const specList = (specs) => {
    if (!specs) return

    return (
      <SpecUl>
        <SpecLi>
          <SpecTitle>Beam</SpecTitle>
          <SpecValue>{specs.beam}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Boat Weight</SpecTitle>
          <SpecValue>{specs.boatWeight}</SpecValue>
        </SpecLi>

        {specs?.wetWeight && (
          <SpecLi>
            <SpecTitle>Est. Wet Weight</SpecTitle>
            <SpecValue>{specs.wetWeight}</SpecValue>
          </SpecLi>
        )}
        <SpecLi>
          <SpecTitle>Chambers</SpecTitle>
          <SpecValue>{specs.chambers}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Internal Beam</SpecTitle>
          <SpecValue>{specs.internalBeam}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Internal Length</SpecTitle>
          <SpecValue>{specs.internalLength}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>LOA</SpecTitle>
          <SpecValue>{specs.loa}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Max Load</SpecTitle>
          <SpecValue>{specs.maxLoad}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Max Passengers</SpecTitle>
          <SpecValue>{specs.maxPassengers}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Max Power</SpecTitle>
          <SpecValue>{specs.maxPower}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Max Recommended Speed</SpecTitle>
          <SpecValue>{specs.maxRecommendedSpeed}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Recommended Power</SpecTitle>
          <SpecValue>{specs.recommendedPower}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Tank Size</SpecTitle>
          <SpecValue>{specs.tankSize ? specs.tankSize : "N/A"}</SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Tube Diameter</SpecTitle>
          <SpecValue>
            {specs.tubeDiameter ? specs.tubeDiameter : "N/A"}
          </SpecValue>
        </SpecLi>
        <SpecLi>
          <SpecTitle>Tube Material</SpecTitle>
          <SpecValue>
            {specs.tubeMaterial ? specs.tubeMaterial : "N/A"}
          </SpecValue>
        </SpecLi>
        {specs?.modelSpecificFeatures?.map((o, i) => (
          <SpecLi key={i}>
            <SpecTitle>{o.title}</SpecTitle>
            <SpecValue>{o.value}</SpecValue>
          </SpecLi>
        ))}
      </SpecUl>
    )
  }

  const CompareButton = (props) => {
    if (buttonsActive) {
      return (
        <StyledCompareButton
          onClick={(e) => handleModelCompare(e, props.model)}
        >
          Compare
        </StyledCompareButton>
      )
    } else {
      return <StyledCompareButton disabled>Compare</StyledCompareButton>
    }
  }

  const CompareScreen = (props) => {
    if (Object.keys(modelComparison).length === 0) return
    return (
      <CompareModels className={props.open && "compare-open"}>
        <Edges size="md">
          <StyledCompareButton
            children="BACK TO SELECTIONS"
            onClick={handleFullComparison}
          />
          <Spacer mb={30} />
          <Grid container spacing={3} justify="center">
            {!!modelComparison &&
              modelComparison.map((o, i) => {
                return (
                  <Grid key={i} item xs={12} md={4}>
                    {o.title && (
                      <ModelImageCont>
                        <BackgroundImage
                          image={
                            o.acf?.introduction.comparisonResultsImage
                              ? o.acf?.introduction.comparisonResultsImage
                              : o.acf?.introduction.image
                              ? o.acf?.introduction.image
                              : null
                          }
                        />
                      </ModelImageCont>
                    )}
                    <Typography variant="h3" children={o.title} />
                    <Spacer mt={20} />
                    {o.uri && (
                      <StyledCompareButton to={o.uri} children={"VIEW MODEL"} />
                    )}
                    <Spacer mb={20} />
                    {specList(o.acf?.specifications)}
                  </Grid>
                )
              })}
          </Grid>
          <Spacer mt={60} />
          <FlexCenter>
            <StyledCompareButton
              children="BACK TO SELECTIONS"
              onClick={handleFullComparison}
            />
          </FlexCenter>
        </Edges>
      </CompareModels>
    )
  }

  return (
    <>
      <PageIntro border={true} image={image} headline={headline} text={text} />
      <OuterContentContainer ref={heightRef}>
        <CompareScreen open={compareOpen} />

        <Spacer mt={{ xs: compareOpen ? 0 : 95, md: 95 }} />

        {filterActive && filteredListGroup.length > 0 && (
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FilterCrumbs>
                  <FilterCrumbTitle>
                    Total Results(s):{" "}
                    <strong>{filteredListGroup.length}</strong>
                  </FilterCrumbTitle>
                  <div>
                    Boat type:{" "}
                    <strong>
                      {Array.isArray(resultsType)
                        ? "CONSOLE"
                        : resultsType.toUpperCase()}
                    </strong>
                  </div>
                  <div>
                    Selected Lengths: &nbsp;
                    <strong>
                      {/* {!!resultsLength &&
                        (resultsLength?.includes("18") ? (
                          <span>17’ and up</span>
                        ) : (
                          <>
                            {resultsLength.map((o, i) => {
                              return (
                                <span key={i}>
                                  {o}
                                  {i === resultsLength.length - 1 ? "" : ", "}
                                </span>
                              )
                            })}
                          </>
                        ))} */}

                      {!!resultsLength &&
                        (resultsLength?.includes("18") ? (
                          <span>
                            {`From ${resultsLength[0]}' to 17’ and up`}
                          </span>
                        ) : (
                          <>
                            <span>
                              {`From ${resultsLength[0]}' to ${
                                resultsLength[resultsLength.length - 1]
                              }'`}
                            </span>
                          </>
                        ))}
                    </strong>
                  </div>
                  <div>
                    Maximum Weight: &nbsp;
                    <strong>{resultsWeight}</strong>
                  </div>
                </FilterCrumbs>
              </Grid>
            </Grid>
          </Edges>
        )}

        {filterActive && filteredListGroup.length === 0 && (
          <ContentContainer>
            <Edges size="cont">
              <Grid container spacing={1}>
                <Grid item md={1}></Grid>
                <StyledBgGridWhite item md={1} />
                <StyledBgGridWhite item md={8}>
                  <Typography
                    variant="h2"
                    children={
                      "Sorry, no results were found for your query. Please review our full list of products below."
                    }
                  />
                  <Spacer mb={60} />
                </StyledBgGridWhite>
              </Grid>
            </Edges>
          </ContentContainer>
        )}

        {filterActive && filteredListGroup.length > 0 && (
          <ContentContainer>
            <Edges size="cont">
              <Grid container spacing={1}>
                <Grid item md={1}></Grid>
                <StyledBgGridWhite item md={1} />
                <StyledBgGridWhite item md={3}>
                  <Typography variant="h2" children={"Comparison Results"} />
                  <Spacer mt={10} />
                  <Textarea content={""} />
                </StyledBgGridWhite>
              </Grid>
            </Edges>
            <Edges size="cont">
              <Spacer pt={80} mb={80}>
                <Grid container spacing={1}>
                  {filteredListGroup &&
                    filteredListGroup.map((o, i) => {
                      return (
                        <StyledGridItem key={i} item sm={6} md={4} lg={3}>
                          <ModelImageCont>
                            <BackgroundImage
                              image={
                                o.acf?.introduction.comparisonResultsImage
                                  ? o.acf?.introduction.comparisonResultsImage
                                  : o.acf?.introduction.image
                                  ? o.acf?.introduction.image
                                  : null
                              }
                            />
                          </ModelImageCont>
                          <Spacer pl={15} pr={15} pb={15}>
                            <Typography variant="h3" children={o.title} />
                            <Textarea
                              content={
                                o.acf.introduction.comparisonResultsText
                                  ? `<p>${o.acf.introduction.comparisonResultsText}</p>`
                                  : truncate(o.acf.introduction.text)
                              }
                            />
                            <Spacer mt={20} />
                            <CompareButton model={o} />
                          </Spacer>
                        </StyledGridItem>
                      )
                    })}
                  <Grid item xs={12}>
                    <Spacer mt={90} mb={60}>
                      <NextSectionTitle>
                        See below for our full list of boats.
                      </NextSectionTitle>
                    </Spacer>
                  </Grid>
                </Grid>
              </Spacer>
            </Edges>
          </ContentContainer>
        )}
        <ContentContainer>
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item md={1}></Grid>
              <StyledBgGridWhite item md={1} />
              <StyledBgGridWhite item md={3}>
                <Typography
                  variant="h2"
                  children={"Luxury Sport and Recreation"}
                />
                <Spacer mt={10} />
                <Textarea content={walkerbayDescription} />
              </StyledBgGridWhite>
            </Grid>
          </Edges>
          <Edges size="cont">
            <Spacer pt={40} mb={80}>
              <Grid container spacing={1}>
                {walkerbayGroup &&
                  walkerbayGroup.map((o, i) => {
                    return (
                      <StyledGridItem key={i} item md={3}>
                        <ModelImageCont>
                          <BackgroundImage
                            image={
                              o.acf?.introduction.comparisonResultsImage
                                ? o.acf?.introduction.comparisonResultsImage
                                : o.acf?.introduction.image
                                ? o.acf?.introduction.image
                                : null
                            }
                          />
                        </ModelImageCont>
                        <Spacer pl={15} pr={15} pb={15}>
                          <Typography variant="h3" children={o.title} />
                          <Textarea
                            content={truncate(o.acf.introduction.text)}
                          />
                          <Spacer mt={20} />
                          <CompareButton model={o} />
                        </Spacer>
                      </StyledGridItem>
                    )
                  })}
              </Grid>
            </Spacer>
          </Edges>
        </ContentContainer>

        <ContentContainer>
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item md={1}></Grid>
              <StyledBgGridWhite item md={1} />
              <StyledBgGridWhite item md={3}>
                <Typography variant="h2" children={"Deluxe"} />
                <Spacer mt={10} />
                <Textarea content={deluxeDescription} />
              </StyledBgGridWhite>
            </Grid>
          </Edges>
          <Edges size="cont">
            <Spacer pt={80} mb={80}>
              <Grid container spacing={1}>
                {deluxeGroup &&
                  deluxeGroup.map((o, i) => {
                    return (
                      <StyledGridItem key={i} item md={3}>
                        <ModelImageCont>
                          <BackgroundImage
                            image={
                              o.acf?.introduction.comparisonResultsImage
                                ? o.acf?.introduction.comparisonResultsImage
                                : o.acf?.introduction.image
                                ? o.acf?.introduction.image
                                : null
                            }
                          />
                        </ModelImageCont>
                        <Spacer pl={15} pr={15} pb={15}>
                          <Typography variant="h3" children={o.title} />
                          <Textarea
                            content={
                              o.acf.introduction.comparisonResultsText
                                ? `<p>${o.acf.introduction.comparisonResultsText}</p>`
                                : truncate(o.acf.introduction.text)
                            }
                          />
                          <Spacer mt={20} />
                          <CompareButton model={o} />
                        </Spacer>
                      </StyledGridItem>
                    )
                  })}
              </Grid>
            </Spacer>
          </Edges>
        </ContentContainer>

        <ContentContainer>
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item md={1}></Grid>
              <StyledBgGridWhite item md={1} />
              <StyledBgGridWhite item md={3}>
                <Typography variant="h2" children={"Explorer Sport"} />
                <Spacer mt={10} />
                <Textarea content={luxurySportDescription} />
              </StyledBgGridWhite>
            </Grid>
          </Edges>
          <Edges size="cont">
            <Spacer pt={80} mb={80}>
              <Grid container spacing={1}>
                {luxurySportGroup &&
                  luxurySportGroup.map((o, i) => {
                    return (
                      <StyledGridItem key={i} item md={3}>
                        <ModelImageCont>
                          <BackgroundImage
                            image={
                              o.acf?.introduction.comparisonResultsImage
                                ? o.acf?.introduction.comparisonResultsImage
                                : o.acf?.introduction.image
                                ? o.acf?.introduction.image
                                : null
                            }
                          />
                        </ModelImageCont>
                        <Spacer pl={15} pr={15} pb={15}>
                          <Typography variant="h3" children={o.title} />
                          <Textarea
                            content={truncate(o.acf.introduction.text)}
                          />
                          <Spacer mt={20} />
                          <CompareButton model={o} />
                        </Spacer>
                      </StyledGridItem>
                    )
                  })}
              </Grid>
            </Spacer>
          </Edges>
        </ContentContainer>

        <ContentContainer>
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item md={1}></Grid>
              <StyledBgGridWhite item md={1} />
              <StyledBgGridWhite item md={3}>
                <Typography variant="h2" children={"Lightweight Deluxe"} />
                <Spacer mt={10} />
                <Textarea content={lightweightDeluxeDescription} />
              </StyledBgGridWhite>
            </Grid>
          </Edges>
          <Edges size="cont">
            <Spacer pt={40} mb={80}>
              <Grid container spacing={1}>
                {lightweightdeluxeGroup &&
                  lightweightdeluxeGroup.map((o, i) => {
                    return (
                      <StyledGridItem key={i} item md={3}>
                        <ModelImageCont>
                          <BackgroundImage
                            image={
                              o.acf?.introduction.comparisonResultsImage
                                ? o.acf?.introduction.comparisonResultsImage
                                : o.acf?.introduction.image
                                ? o.acf?.introduction.image
                                : null
                            }
                          />
                        </ModelImageCont>
                        <Spacer pl={15} pr={15} pb={15}>
                          <Typography variant="h3" children={o.title} />
                          <Textarea
                            content={truncate(o.acf.introduction.text)}
                          />
                          <Spacer mt={20} />
                          <CompareButton model={o} />
                        </Spacer>
                      </StyledGridItem>
                    )
                  })}
              </Grid>
            </Spacer>
          </Edges>
        </ContentContainer>

        <ContentContainer>
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item md={1}></Grid>
              <StyledBgGridWhite item md={1} />
              <StyledBgGridWhite item md={3}>
                <Typography variant="h2" children={"Lightweight"} />
                <Spacer mt={10} />
                <Textarea content={lightweightDescription} />
              </StyledBgGridWhite>
            </Grid>
          </Edges>
          <Edges size="cont">
            <Spacer pt={40} mb={80}>
              <Grid container spacing={1}>
                {lightweightGroup &&
                  lightweightGroup.map((o, i) => {
                    return (
                      <StyledGridItem key={i} item md={3}>
                        <ModelImageCont>
                          <BackgroundImage
                            image={
                              o.acf?.introduction.comparisonResultsImage
                                ? o.acf?.introduction.comparisonResultsImage
                                : o.acf?.introduction.image
                                ? o.acf?.introduction.image
                                : null
                            }
                          />
                        </ModelImageCont>
                        <Spacer pl={15} pr={15} pb={15}>
                          <Typography variant="h3" children={o.title} />
                          <Textarea
                            content={truncate(o.acf.introduction.text)}
                          />
                          <Spacer mt={20} />
                          <CompareButton model={o} />
                        </Spacer>
                      </StyledGridItem>
                    )
                  })}
              </Grid>
            </Spacer>
          </Edges>
        </ContentContainer>

        {/* <ContentContainer>
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item md={1}></Grid>
              <StyledBgGridWhite item md={1} />
              <StyledBgGridWhite item md={3}>
                <Typography variant="h2" children={"Supertender Open"} />
                <Spacer mt={10} />
                <Textarea content={supertenderOpenDescription} />
              </StyledBgGridWhite>
            </Grid>
          </Edges>
          <Edges size="cont">
            <Spacer pt={40} mb={80}>
              <Grid container spacing={1}>
                {supertenderopenGroup &&
                  supertenderopenGroup.map((o, i) => {
                    return (
                      <StyledGridItem key={i} item md={3}>
                        <ModelImageCont>
                          <BackgroundImage
                            image={
                              o.acf?.introduction.comparisonResultsImage
                                ? o.acf?.introduction.comparisonResultsImage
                                : o.acf?.introduction.image
                                ? o.acf?.introduction.image
                                : null
                            }
                          />
                        </ModelImageCont>
                        <Spacer pl={15} pr={15} pb={15}>
                          <Typography variant="h3" children={o.title} />
                          <Textarea
                            content={truncate(o.acf.introduction.text)}
                          />
                          <Spacer mt={20} />
                          <CompareButton model={o} />
                        </Spacer>
                      </StyledGridItem>
                    )
                  })}
              </Grid>
            </Spacer>
          </Edges>
        </ContentContainer> */}

        {/* <ContentContainer>
          <Edges size="cont">
            <Grid container spacing={1}>
              <Grid item md={1}></Grid>
              <StyledBgGridWhite item md={1} />
              <StyledBgGridWhite item md={3}>
                <Typography variant="h2" children={"Superlight Open"} />
                <Spacer mt={10} />
                <Textarea content={superlightOpenDescription} />
              </StyledBgGridWhite>
            </Grid>
          </Edges>
          <Edges size="cont">
            <Spacer pt={40} mb={80}>
              <Grid container spacing={1}>
                {superlightopenGroup &&
                  superlightopenGroup.map((o, i) => {
                    return (
                      <StyledGridItem key={i} item md={3}>
                        <ModelImageCont>
                          <BackgroundImage
                            image={
                              o.acf?.introduction.comparisonResultsImage
                                ? o.acf?.introduction.comparisonResultsImage
                                : o.acf?.introduction.image
                                ? o.acf?.introduction.image
                                : null
                            }
                          />
                        </ModelImageCont>
                        <Spacer pl={15} pr={15} pb={15}>
                          <Typography variant="h3" children={o.title} />
                          <Textarea
                            content={truncate(o.acf.introduction.text)}
                          />
                          <Spacer mt={20} />
                          <CompareButton model={o} />
                        </Spacer>
                      </StyledGridItem>
                    )
                  })}
              </Grid>
            </Spacer>
          </Edges>
        </ContentContainer> */}

        {!drawerState && (
          <ConsistantDrawerToggle>
            <ChevronClose onClick={toggleDrawer(true)}>
              {getIcon("ChevronDown")}
            </ChevronClose>
          </ConsistantDrawerToggle>
        )}
        <StyledDrawer
          anchor="bottom"
          open={drawerState}
          variant="persistent"
          onClose={toggleDrawer(false)}
        >
          <ChevronClose onClick={toggleDrawer(false)}>
            {getIcon("ChevronDown")}
          </ChevronClose>
          <CompareContainer>
            <Edges size="cont">
              <Grid container spacing={1}>
                <Grid item md={1}></Grid>
                <StyledBgGrid item md={1} />
                <StyledBgGrid item md={3}>
                  <h3 className="section-title">
                    Compare Models{" "}
                    <span className="text-small">(select up to 3)</span>
                  </h3>
                </StyledBgGrid>
              </Grid>
            </Edges>
            <Edges size="cont">
              <Spacer pb={{ xs: 0, md: 30 }}>
                <StyledCompareContainer container>
                  <GridHideXs item md={2}></GridHideXs>
                  {modelComparison &&
                    modelComparison.map((o, i) => {
                      return (
                        <CompareModelGrid key={i} item xs={6} md={2}>
                          <ModelImageCont short={true}>
                            <BackgroundImage
                              image={
                                o.acf?.introduction.comparisonResultsImage
                                  ? o.acf?.introduction.comparisonResultsImage
                                  : o.acf?.introduction.image
                                  ? o.acf?.introduction.image
                                  : null
                              }
                            />
                          </ModelImageCont>
                          <Spacer mt={{ xs: 10, md: 20 }} />
                          <ModelCompareTitle>{o.title}</ModelCompareTitle>
                          <Spacer mb={{ xs: 10, md: 15 }} />
                          {Object.keys(o).length !== 0 && (
                            <CloseButton onClick={() => handleClearModel(o)}>
                              {getIcon("CloseButton")}
                            </CloseButton>
                          )}
                        </CompareModelGrid>
                      )
                    })}
                  <Grid item xs={12} md={2}>
                    <SpacerFlexXs mt={{ xs: 30, md: 0 }} ml={{ xs: 0, md: 20 }}>
                      <StyledButton
                        disabled={modelsCompared === 0 ? true : false}
                        children="Compare"
                        onClick={handleFullComparison}
                      />
                      <StyledButton
                        children="Clear All"
                        onClick={handleClear}
                      />
                    </SpacerFlexXs>
                  </Grid>
                </StyledCompareContainer>
              </Spacer>
            </Edges>
          </CompareContainer>
        </StyledDrawer>
      </OuterContentContainer>
    </>
  )
}

const ContentContainer = styled.div`
  background: #fff;
  color: #252525;
  position: relative;
  margin-top: 1px;

  &:before {
    content: "";
    background: ${colors.text.dark};
    height: 1px;
    position: absolute;
    width: 100%;
    top: 36px;
  }

  @media screen and (min-width: 768px) {
    &:before {
      content: "";
      background: ${colors.text.dark};
      height: 1px;
      position: absolute;
      width: 100%;
      top: 30px;
    }
  }
`

const OuterContentContainer = styled.div`
  position: relative;

  &.comparison-open {
    ${ContentContainer} {
      height: 0px;
      opacity: 0;
      z-index: -1;
    }
  }
`

const CompareContainer = styled.div`
  background: #000;
  color: ${colors.text.light};
  position: relative;

  .section-title {
    background: #000;
    margin: 10px 0px;
  }

  .text-small {
    font-size: 16px;
    font-weight: 300;
  }

  &:before {
    content: "";
    background: ${colors.text.light};
    height: 1px;
    position: absolute;
    width: 100%;
    top: 30px;
  }

  @media screen and (min-width: 768px) {
    .section-title {
      margin: 1em 0px;
    }
  }
`

const StyledBgGrid = styled(Grid)`
  background: #000;
  position: relative;
`

const StyledBgGridWhite = styled(Grid)`
  background: #fff;
  position: relative;
`

const StyledButton = styled(Button)`
  border: 1px solid ${colors.text.light};
  padding: 7px 15px;
  color: #e9e1bb;
  margin-right: 15px;

  @media (min-width: 768px) {
    min-width: 185px;
    margin: 0px 0px 15px 0px;
  }

  &.Mui-disabled {
    background: white;
  }
`

const CompareModelGrid = styled(Grid)`
  text-align: center;
`

const GridHideXs = styled(Grid)`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const ModelCompareTitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`

const StyledCompareButton = styled(Button)`
  border: 1px solid ${colors.secondary};
  padding: 7px 15px;
  color: ${colors.text.dark};
  min-width: 185px;
`

const CloseButton = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
  padding: 5px 10px;
`

const SpacerFlexXs = styled(Spacer)`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    display: block;
  }
`

const NextSectionTitle = styled.div`
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  width: 100%;
`

const CompareModels = styled.div`
  background: #fff;
  left: 0px;
  opacity: 0;
  position: absolute;
  padding: 30px 0px 60px;
  top: 0px;
  width: 100%;
  z-index: -1;

  &.compare-open {
    opacity: 1;
    z-index: 999;
  }
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`

const FilterCrumbs = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  position: relative;
  margin-bottom: -45px;
  text-align: right;
  top: -45px;

  > div {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  @media (min-width: 768px) {
    > div {
      margin-bottom: 0px;
    }
  }
`

const FilterCrumbTitle = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: right;
`

const ModelImageCont = styled(({ short, ...rest }) => <div {...rest} />)`
  border: 1px solid #9c9c9c;
  height: ${(props) => (props.short ? "65px" : "190px")};
  position: relative;

  @media (min-width: 768px) {
    height: ${(props) => (props.short ? "125px" : "190px")};

    &:before {
      content: "add a product";
      color: white;
      height: 100%;
      left: 0px;
      padding-top: 25%;
      position: absolute;
      text-align: center;
      top: 0px;
      width: 100%;
      z-index: 0;
    }
  }
`

const ConsistantDrawerToggle = styled.div`
  background: #000;
  bottom: 0px;
  position: fixed;
  left: 0px;
  text-align: center;
  width: 100%;

  button {
    padding: 10px 30px;
    transform: rotate(180deg);
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorBottom {
    background: #000;
    padding: 10px 0px 15px;
  }

  button {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 768px) {
    padding: 15px 0px 30px;
  }
`

const ChevronClose = styled.button`
  background: transparent;
  border: 0px;
  color: ${colors.tertiary};

  &:active,
  &:focus {
    box-shadow: inset 0px 0px 0px 1px ${colors.tertiary};
    outline: none;
  }
`

const StyledCompareContainer = styled(Grid)`
  .MuiGrid-item {
    padding: 0px 4px;
  }

  @media (min-width: 768px) {
    .MuiGrid-item {
      padding: 4px;
    }
  }
`

const StyledGridItem = styled(Grid)`
  margin-bottom: 30px;
`

const SpecUl = styled.ul`
  margin: 0px;
  padding: 0px;
`

const SpecLi = styled.li`
  border-bottom: 1px solid #9c9c9c;
  padding: 20px 0px;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  min-height: 130px;

  &:first-of-type {
    border-top: 1px solid #9c9c9c;
  }

  > div {
    width: 100%;
  }
`
const SpecTitle = styled.div`
  font-weight: 700;
  text-transform: uppercase;
`

const SpecValue = styled.div`
  min-height: 70px;
`

export const CollectionQuery = graphql`
  query Modelcomparison($id: String!) {
    page: wpPage(id: { eq: $id }) {
      templateModelcomparison {
        modelComparisonFields {
          modelComparisonHero {
            text
            headline
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
          sections {
            deluxe
            luxurySport
            lightweightDeluxe
            lightweight
            supertenderOpen
            superlightOpen
          }
        }
      }
    }
    boats: allWpBoat {
      nodes {
        uri
        title
        acf {
          introduction {
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
                }
              }
            }
            comparisonResultsText
            comparisonResultsImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
                }
              }
            }
          }
          family
          boatType
          boatMaximumWeight
          boatLength
          specifications {
            modelSpecificFeatures {
              title
              value
            }
            beam
            boatWeight
            wetWeight
            fieldGroupName
            chambers
            internalBeam
            internalLength
            loa
            maxLoad
            maxPassengers
            maxPower
            maxRecommendedSpeed
            recommendedPower
            tankSize
            tubeDiameter
            tubeMaterial
          }
        }
        boatfamilies {
          nodes {
            id
            name
            description
          }
        }
      }
    }
  }
`
